<template>
	<div class="newsCenter_page">
		<Banner :adCategory="7"></Banner>
		<div class="content">
			<div class="navBox display">
				<div
					class="list display pointer"
					:class="navIndex == index ? 'active' : ''"
					v-for="(item, index) in navData"
					:key="index"
					@click="changeNav(item.id, index)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="school_dynamic flex">
				<div
					class="school_dynamic_list pointer"
					:class="schoolDynamicIndex == index ? 'active' : ''"
					v-for="(item, index) in newsBannre"
					:key="index"
					@mouseenter="enter(index)"
					@mouseleave="schoolDynamicIndex = null"
					@click="toDetails(item)"
				>
					<img :src="item.newsImgUrl" alt="" />
					<div class="cont">
						<div class="name row">{{ item.newsTitle }}</div>
						<div class="content_text">
							{{ item.introduction }}
						</div>
						<div class="published_time">{{ item.createTime }}</div>
					</div>
				</div>
			</div>
			<div class="more_dynamic">
				<div
					class="list pointer Between"
					v-for="(item, index) in newsData"
					:key="index"
					@click="toDetails(item)"
				>
					<div class="name row">{{ item.newsTitle }}</div>
					<div class="time">{{ item.createTime }}</div>
				</div>
				<div
					class="loadMore display pointer"
					@click="loadMoreBtn"
					v-if="totalNum > 10"
				>
					{{ loadMore }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Banner from '@/components/banner'
export default {
	name: 'newsCenter',
	components: {
		Banner,
	},
	data() {
		return {
			loadMore: '加载更多>>',
			navIndex: 0,
			schoolDynamicIndex: null,
			moreDynamicIndex: null,
			navData: [
				{
					id: 1,
					name: '学校动态',
				},
				{
					id: 2,
					name: '行业资讯',
				},
			],

			moreDynamicList: [],
			params: {
				page: 1,
				limit: 10,
				newsType: 1,
			},
			newsData: [],
			newsBannre: [],
			totalNum: 0,
		}
	},
	mounted() {
		this.getData()
		document.documentElement.scrollTop = 0
	},
	filters: {
		filtersText(val) {
			// 要判断一下,如果是空就返回空字符串,不然会报错
			if (val != null && val != '') {
				var reg = /[\u4e00-\u9fa5]/g
				var names = val.match(reg)
				val = names.join('')
				return val.substr(0, 50) //获取富文本文字内容的前50个字符
			} else return ''
		},
	},
	methods: {
		getData() {
			this.api.findPageNews(this.params).then((res) => {
				res.data.forEach((item) => {
					item.createTime = item.createTime.slice(0, 10)
				})
				this.newsData = res.data || []
				if (res.data.length != 0) {
					this.newsBannre = res.data.slice(0, 3)
				}
				this.totalNum = res.count
			})
		},
		changeNav(id, index) {
			this.navIndex = index
			this.params.newsType = id
			this.getData()
		},
		enter(index) {
			this.schoolDynamicIndex = index
		},
		// 详情
		toDetails(item) {
			this.$router.push({
				path: '/homes/newsCenterDetails',
				query: {
					id: item.id,
					ids: 8,
				},
			})
		},
		// 加载更多
		loadMoreBtn() {
			if (this.totalNum == this.newsData.length) {
				this.loadMore = '没有更多了'
			} else {
				this.params.limit += 10
				this.getData()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.newsCenter_page {
	.navBox {
		width: 680px;
		height: 56px;
		background: #ffffff;
		border: 1px solid #dedede;
		margin: 0 auto;
		margin-top: 100px;
		margin-bottom: 50px;
		.active {
			background: #ff881e;
			color: white !important;
		}
		.list {
			width: 340px;
			height: 56px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}
	}
	.school_dynamic {
		.active {
			border: 2px solid #003c7e !important;
		}
		.school_dynamic_list:nth-child(1) {
			margin-left: 0px !important;
		}
		.school_dynamic_list {
			width: 380px;
			height: 480px;
			border: 2px solid rgba(222, 222, 222, 0.96);
			margin-left: 34px;
			img {
				width: 376px;
				height: 258px;
			}
			.cont {
				padding: 25px 30px;
				box-sizing: border-box;
				.name {
					width: 323px;
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #003c7e;
					line-height: 20px;
				}

				.content_text {
					width: 323px;
					height: 92px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 24px;
					margin-top: 18px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
				}
				.published_time {
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #003c7e;
					line-height: 18px;
					margin-top: 20px;
				}
			}
		}
	}
	.more_dynamic {
		width: 1200px;
		border-top: 2px solid #efefef;
		margin-top: 30px;
		padding-bottom: 140px;
		.list:hover {
			background: #f5f5f5;
		}
		.list {
			width: 1200px;
			height: 79px;
			border-bottom: 2px solid #efefef;
			padding-left: 25px;
			padding-right: 18px;
			box-sizing: border-box;
			.name {
				width: 786px;
				font-size: 18px;
				color: #333333;
			}
			.time {
				font-size: 18px;
				color: #888888;
			}
		}
		.loadMore {
			width: 100%;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 600;
			color: #333;
			margin-top: 32px;
		}
	}
}
</style>